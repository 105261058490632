<!--suppress JSUnresolvedVariable -->
<template>
  <div class="root">
    <div class="body">
      <div class="video" v-for="item in videos" :key="item.id">
        <img :src="item.thumbnail_url" @click="handleVideoThumbnailClicked(item)" class="thumbnail"/>
        <p>{{ item.name }}</p>
      </div>
    </div>
    <el-dialog v-if="videoPlayDialogVisible" :destroy-on-close="true" :visible.sync="videoPlayDialogVisible"
               title="视频播放" width="80%">
      <Player id="videoPlayer" :video-id="videoId" :video-url="videoUrl" :key="videoId"/>
    </el-dialog>
  </div>
</template>

<script>
import network from "@/network";
import Player from "@/common/Player";

export default {
  name: "VideosView",
  components: {Player},
  created() {
    network.get('/videos/getAllVideos').then(res => {
      this.videos = res.data;
      // console.log(this.videos)

      // this.videos.forEach(val => {
      //   val.thumbnail_url = process.env.VUE_APP_BASEURL + val.thumbnail_url;
      //   val.video_url = process.env.VUE_APP_BASEURL + val.video_url;
      // })
    })
  },
  data() {
    return {
      videos: [],
      videoUrl: '',
      videoId: '',
      videoPlayDialogVisible: false
    }
  },
  methods: {
    handleVideoThumbnailClicked(video) {
      this.videoUrl = video.video_url;
      this.videoId = video.id;
      this.videoPlayDialogVisible = true;
    }
  }
}
</script>

<style scoped>
.video {
  float: left;
  margin: 10px;


}

.video .thumbnail {
  width: 320px;
  height: 240px;
}

.video p {
  text-align: center;
  width: 320px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>