<template>
  <div class="root">
    <video id="video" controls width="100%"/>
  </div>
</template>


<script>
import shaka from "shaka-player"
import network from "@/network";

export default {
  name: "Player",
  props: {
    videoUrl: {
      type: String,
      default: ''
    },
    videoId: {
      type: String,
      default: ''
    }
  },
  mounted() {
    network.get(`videos/getLicenseById/${this.videoId}`).then(res => {
      shaka.polyfill.installAll();
      if (shaka.Player.isBrowserSupported()) {
        const player = new shaka.Player(document.getElementById('video'));
        player.addEventListener('error', this.onErrorEvent);
        player.configure({
          drm: {
            servers: {
              'com.widevine.alpha': 'https://557a5561.drm-widevine-licensing.axprod.net/AcquireLicense',
              'com.microsoft.playready': 'https://557a5561.drm-playready-licensing.axprod.net/AcquireLicense',
              'com.apple.fps.1_0': 'https://557a5561.drm-fairplay-licensing.axprod.net/AcquireLicense'
            },
            advanced: {
              'com.widevine.alpha': {
                'videoRobustness': 'SW_SECURE_CRYPTO',
                'audioRobustness': 'SW_SECURE_CRYPTO'
              }
            }
          },
          manifest: {
            retryParameters: {
              timeout: 60000,       // timeout in ms, after which we abort
              stallTimeout: 5000,  // stall timeout in ms, after which we abort
              connectionTimeout: 10000, // connection timeout in ms, after which we abort
              maxAttempts: 2,   // the maximum number of requests before we fail
              baseDelay: 1000,  // the base delay in ms between retries
              backoffFactor: 2, // the multiplicative backoff factor between retries
              fuzzFactor: 0.5,  // the fuzz factor to apply to each retry delay
            }
          }
        });
        player.getNetworkingEngine().registerRequestFilter((type, request) => {
          if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
            request.headers['X-AxDRM-Message'] = res.data;
          }
        });
        player.load(this.videoUrl).then(function () {
          console.log('The video has now been loaded!');
        }).catch(this.onError);
      } else {
        alert("Browser not supported!")
        console.error('Browser not supported!');
      }
    })
  },
  methods: {
    onErrorEvent(event) {
      this.onError(event.detail);
    },

    onError(error) {
      alert('Error code: ' + error.code)
      alert(error.data[0])
      console.error('Error code', error.code, 'object', error);
    }
  }
}
</script>

<style scoped>

</style>